import React from 'react'
import { useAuth } from '../../contexts/authContext'
import { db } from '../../firebase/firebase'
import { addDoc, collection, getDocs, getDoc, doc, updateDoc, orderBy, limit, query, where, serverTimestamp, onSnapshot } from "firebase/firestore";

import { Container, Button, Row, Col, Card, CardTitle, CardText, Input} from 'reactstrap';
import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'


const Home = () =>{
    const statusMap = {
      1: "Sending content to server",
      2: "Server received content",
      3: "Server processing",
      4: "Permission denied",
      5: "Server finalizing solution",
      6: "Solution received",
      7: "Entry was previously processed",
      8: "Entry was previously removed"
    }
    const getStatus = (status) => {
      if (status in statusMap ) {
        return statusMap[status]
      }
      return "Unknown status"
    }

    const { currentUser,userLoggedIn } = useAuth()
    const [selectedLanguage, setSelectedLanguage] = useState('python'); 
    const [entries, setEntries] = useState();
    const [docId, setDocId] = useState();
    const [text, setText] = useState("");
    const entriesCollectionRef = collection(db, "prompts_by_user", currentUser.uid, "entries");
    const recentActiveRef = query(entriesCollectionRef, where("active", "==", true), orderBy("time",  "desc"), limit(1))
    // update entries on snapshot, ordered by time, limit 1
    
    // const qry = query(entriesCollectionRef, orderBy("time", "desc"), limit(1));
    const [lastEntry, setLastEntry] = useState(null);
    const [active, setActive] = useState(false)
    const handleCopyClick = async () => {
      try {
          await navigator.clipboard.writeText(entries[0].response);
      } catch (err) {
          console.error(
              "Unable to copy to clipboard.",
              err
          );
          alert("Copy to clipboard failed.");
      }
  };

  const handleGenerate = async () => {
    try {
        await addDoc(collection(db, "prompts"), {
          text:  text,
          language: selectedLanguage,
          time: serverTimestamp(),
          uid: currentUser.uid,
          email: currentUser.email,
          docid: docId,
        });
        } catch (error) {
          console.error(
            "Unable to delete entry.", error
        );
    }
};

  const handeDelete = async () => {
    try {
       const docRef = doc(db, "prompts_by_user", currentUser.uid, "entries", entries[0].id);
        // console.log(entries[0].id)
        await updateDoc(docRef, {
          active: false
        });
        } catch (error) {
          console.error(
            "Unable to delete entry.", error
        );
    }
};
    useEffect(() => {
      
      const getEntries = async () => {
        const data = await getDocs(recentActiveRef)
        // setEntries(data.docs.map((elem) => ({ ...elem.data(), id: elem.id })))
        if (data && data.docs && data.docs.length > 0) {
                   setText(data.docs[0].data().text)
                  setEntries(data.docs.map((elem) => ({ ...elem.data(), id: elem.id })))
            }
      }
          



      const getHeartbeat = async () => {
        if (currentUser && currentUser.uid){
        const data = await getDoc(doc(db, "heartbeats", currentUser.uid));
        // console.log(data)
        if (data && data.data() ){
          if (data.data().login_time){
            // console.log(data.data().login_time);
            setActive(true)
          } 
          else if (data.data().logout_time){
            setActive(false)
            // console.log(data.data().login_time);
          }
        }
      }
      }
      const subscribeToHeartbeat = onSnapshot(doc(db, "heartbeats", currentUser.uid), (doc) => {
        // console.log(" in onsnapshot ")
        // console.log(snapshot.docs.data())
        // console.log(doc.data())
        if (doc && doc.data()){
          if (doc.data().login_time){
            // console.log(data.data().login_time);
            setActive(true)
          } 
          else{
            setActive(false)
            // console.log(data.data().login_time);
          }
        }
        });
  
      const subscribe = onSnapshot(recentActiveRef, (snapshot) => {
        // console.log(" in onsnapshot ")
        // console.log(snapshot.docs.data())
        snapshot.docs.map((doc) => {
        let data =doc.data();
        // console.log(data)
        setText(data.text)
        setEntries([{ ...data, id: data.id }])
        setDocId(doc.id)
        });
      });

      
    //  getEntries()
    //  getHeartbeat()
    
     return ()=> subscribe() && subscribeToHeartbeat()
    }, [])

    return (
      userLoggedIn
      ?
     
 <>

       <div className='text-2xl font-bold pt-14'>
        <Container>
        <a href="https://chrome.google.com/webstore/detail/ebdcbiijhjkdbgmhdgldjklbepkblndf">Get extension from Chrome store</a>

          <Row>
            <Col sm="6">
            <p>Your Unique User ID is {currentUser.uid}</p>
        <p>Please copy and paste the User ID to your extension to capture content.</p>
            </Col>
            <Col sm="6">
            <p>Chrome extension status: {active ? <Button color="success">Connected </Button> :<Button color="danger">Disconnected </Button> }</p>
        <div>
        Language:
        <select
      value={selectedLanguage} // ...force the select's value to match the state variable...
      onChange={e => setSelectedLanguage(e.target.value)} // ... and update the state variable on any change!
    >
      <option value="python">python</option>
      <option value="java">Java</option>
      <option value="C++">C++</option>
      <option value="Javascript">Javascript</option>
    </select>
        </div>
            </Col>
          </Row>
        </Container>
        {/* <div className='grid grid-cols-2'> */}
       
               
                  <div>
                    <Container>
                    <Row>
                      <Col sm="6">
                        <Card body>
                          <CardTitle tag="h5">
                            Captured Content
                          </CardTitle>
                          <CardText>
                          <Input
    bsSize="lg"
    type="textarea"
    height = "400px"
    placeholder="Run Chrome extension to capture content"
    value={text}
    rows="10"
    onChange={(e) => setText(e.target.value)}
  >
  </Input>       
                  
                          </CardText>
                         
                          <Button  color="info" onClick={handleGenerate}>
                            Generate Solution
                          </Button>
                        </Card>
                        {entries && entries.map(entry => {
                         return (
                      <Card body>
                          <CardTitle tag="h5">
                            Solution Code:
                          </CardTitle>
                          <CardText className="fw-normal">
                          <Input
    bsSize="lg"
    type="textarea"
    rows="30"
    value={entry && entry.response}
  >
  </Input>    
  <Button color="info" onClick={handleCopyClick}>
                            Copy
                          </Button>      
                          </CardText>
                        </Card>
                         );
                        })}
                      </Col>
                      
                      <Col sm="6">
                      {entries && entries.map(entry => {
                         return (
                        <Card body>
                          <CardTitle tag="h5">
                            Solution Walkthrough:
                          </CardTitle>
                          <CardText className="fw-normal">
                          Status: {entry.status && getStatus(entry.status)}
                          <br/>
                          {/* {entry && entry.questions && <h5>Clarifying Questions </h5>}
                          {entry && entry.questions && entry.questions.split('\n').map(str => <p>{str}</p>)}
                          {entry && entry.strategy && <h5> Strategy </h5>}
                          {entry && entry.strategy && entry.strategy.split('\n').map(str => <p>{str}</p>)}
                          {entry && entry.complexity && <h5> Complexity </h5>}
                          {entry && entry.complexity && entry.complexity.split('\n').map(str => <p>{str}</p>)}
                          {entry && entry.steps && <h5> Steps </h5>}
                          {entry && entry.steps && entry.steps.split('\n').map(str => <p>{str}</p>)} */}
                          {entry && entry.initial && entry.initial.split('\n').map(str => <ReactMarkdown>{str}</ReactMarkdown>)}
  {/* <div class="demo" contenteditable>{entry.response.replace("/t", "w").split('\n').map(str => <p>{str}</p>)}</div> */}
  <br/>
  {entry && entry.summary && entry.summary.split('\n').map(str => <ReactMarkdown>{str}</ReactMarkdown>)}             
                          </CardText>
                        
                          <Button color="danger" onClick={handeDelete}>Delete</Button>
                        </Card>
                          );
                        })}
                      </Col>
                      <Col sm="6">
                      
                        </Col>
                     
                    </Row>
                    <a href="https://firebasestorage.googleapis.com/v0/b/handwritten-852e8.appspot.com/o/AlgoAdvanceChromeExtensionAllURL.zip?alt=media&token=75de9324-9afa-4316-82b3-1dc89045d025">Download extension with no URL restriction</a>
                    </Container>
                 </div>
               
        {/* </div> */}


        
        </div>
        </>
        :

 <>
  {!userLoggedIn && (<Navigate to={'/login'} replace={true} />)}

 <div className='text-2xl font-bold pt-14'>
   Please login to access the workspace.
 </div>
</>
        
    )
}

export default Home