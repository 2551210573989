import Login from "./components/auth/login";
import Register from "./components/auth/register";

import Header from "./components/header";
import Systems from "./components/systems";
import SystemScreen from "./components/systems-screen";
import Home from "./components/home";
import Screen from "./components/screen";
import Checkout from "./components/checkout";
import Profile from "./components/profile";
import Problems from "./components/problems";
import Privacy from "./components/privacy";
import LandingPage from "./components/landing";

import { AuthProvider } from "./contexts/authContext";
import { useRoutes } from "react-router-dom";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/css/argon-design-system-react.css";
import Footer from "./components/footer";
import TermsOfService from "./components/terms-of-service";
function App() {
  const routesArray = [
    {
      path: "*",
      element: <LandingPage  />,
    },
    {
      path: "/problems",
      element: <Problems />,
    },
    {
      path: "/landing",
      element: <LandingPage />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/home",
      element: <Home />,
    }, 
    {
      path: "/systems",
      element: <Systems />,
    }, 
    {
      path: "/systems-screen",
      element: <SystemScreen />,
    }, 
    {
      path: "/screen",
      element: <Screen />,
    }, 
    { path: "/checkout",
    element: <Checkout />,
  },
    {
      path: "/profile",
    element: <Profile />,
    },
    {
      path: "/privacy",
    element: <Privacy />,
    },
    {
      path: "/terms-of-service",
    element: <TermsOfService />,
    }
  ];
  let routesElement = useRoutes(routesArray);
  return (
    <AuthProvider>
      <Header />
      <div className="w-full flex flex-col">{routesElement}</div>

      {/* <div className="w-full h-screen flex flex-col">{routesElement}</div> */}
      <Footer />
    </AuthProvider>
  );
}

export default App;
